import React from 'react'
import {makeStyles} from "@material-ui/core/styles"
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'
import TwitterIcon from '@material-ui/icons/Twitter'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import {Link} from 'gatsby'

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  footerLink: {
    color: '#126cb5',
    fontFamily: '\'Open Sans\', sans-serif',
    textDecoration: 'none'
  },
}));

export default () => {
  const classes = useStyles()
  return (
      <Box style={{backgroundColor: '#FBFBFA', marginTop: 50, paddingBottom: 30, paddingTop: 30}}>
        <Container>
          <Box marginBottom={3}>
            <Grid container>
              <Grid item xs={12} md={4}>
                <Box marginBottom={3}>
                  <Link to={`/about`} className={classes.footerLink}>
                    <Typography variant={'h5'} align={'center'}>About Us</Typography>
                  </Link>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box marginBottom={3}>
                  <Link to={`/news`} className={classes.footerLink}>
                    <Typography variant={'h5'} align={'center'}>News</Typography>
                  </Link>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box marginBottom={3}>
                  <Link to={`/contact`} className={classes.footerLink}>
                    <Typography variant={'h5'} align={'center'}>Contact Us</Typography>
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box marginBottom={3}>
            <Grid container justify={'center'} spacing={4}>
              <Grid item>
                <FacebookIcon color={'primary'} fontSize={'large'}/>
              </Grid>
              <Grid item>
                <TwitterIcon color={'primary'} fontSize={'large'}/>
              </Grid>
              <Grid item>
                <InstagramIcon color={'primary'} fontSize={'large'}/>
              </Grid>
              <Grid item>
                <LinkedInIcon color={'primary'} fontSize={'large'}/>
              </Grid>
            </Grid>
          </Box>
          <Box marginBottom={3}>
            <Grid container>
              <Grid item className='text-center text-md-right' xs={12} md={3}/>
              <Grid item className='text-center text-md-right' xs={12} md={2}>
                <Link to={`/`}>
                  <Typography variant={'body2'} align={'center'} gutterBottom>Terms of Service</Typography>
                </Link>
              </Grid>
              <Grid item className='text-center text-md-right' xs={12} md={2}>
                <Link to={`/`}>
                  <Typography variant={'body2'} align={'center'} gutterBottom>Privacy Policy</Typography>
                </Link>
              </Grid>
              <Grid item className='text-center text-md-right' xs={12} md={2}>
                <Link to={`/sitemap.xml`}>
                  <Typography variant={'body2'} align={'center'} gutterBottom>Sitemap</Typography>
                </Link>
              </Grid>
              <Grid item className='text-center text-md-right' xs={12} md={3}/>
            </Grid>
          </Box>
          <Box marginBottom={5}>
            <Grid container>
              <Grid item className='text-center text-md-right' xs={12} md={3}/>
              <Grid item className='text-center text-md-right' xs={12} md={6}>
                <Typography variant={'body2'} align={'center'} gutterBottom>2020 &copy; Signxperts.com. All right reserved.</Typography>
              </Grid>
              <Grid item className='text-center text-md-right' xs={12} md={3}/>
            </Grid>
          </Box>
        </Container>
      </Box>
  )
}
